// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-produkte-fehlzeitenerfassung-js": () => import("./../src/pages/info/produkte/fehlzeitenerfassung.js" /* webpackChunkName: "component---src-pages-info-produkte-fehlzeitenerfassung-js" */),
  "component---src-pages-info-produkte-klassenbuch-js": () => import("./../src/pages/info/produkte/klassenbuch.js" /* webpackChunkName: "component---src-pages-info-produkte-klassenbuch-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kostenlos-testen-js": () => import("./../src/pages/kostenlos-testen.js" /* webpackChunkName: "component---src-pages-kostenlos-testen-js" */),
  "component---src-pages-markdownseiten-js": () => import("./../src/pages/markdownseiten.js" /* webpackChunkName: "component---src-pages-markdownseiten-js" */),
  "component---src-pages-nutzungsbedingungen-js": () => import("./../src/pages/nutzungsbedingungen.js" /* webpackChunkName: "component---src-pages-nutzungsbedingungen-js" */),
  "component---src-templates-simple-js": () => import("./../src/templates/simple.js" /* webpackChunkName: "component---src-templates-simple-js" */)
}

