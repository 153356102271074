import { createMuiTheme } from "@material-ui/core";

const mainColor = "#81BD52";
const white = "#fff";
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            "Roboto",
            "Source Sans Pro",
            "Open Sans",
            "sans-serif"
        ].join(","),
    },
    palette: {
        primary1Color: mainColor,
        primary: {
            main: mainColor,
            contrastText: white
        }
    },
    breakpoints: {
        values: {
            xxs: 0,
            xs: 300,
            s: 450,
            sm: 600,
            md: 705,
            lg: 1280,
            xl: 1920,
        },
    },
});

export default theme;
